
.services{

    
    display: flex;
    height: 90vh;
    align-items: top;
    justify-content: center;
    font-size: 3rem;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    color :black;
    font-size: 100px;
  
  
  
  }
  .select-icon {
    width: 35px;
    height: 35px;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center;
}
