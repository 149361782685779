.footer-container{

    background-color: black;
    padding: 4rem 0 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.footer-subscription{

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-bottom: 10px;
    padding: 24px;
    color: rgb(243, 236, 236) ;
}
.footer-subscription > p{

    font-family: 'Times New Roman', Times, serif;

}
.footer-subscription-heading{
    margin-bottom:24px ;
    font-size: 24px;

}
.footer-subscription-text{

    margin-bottom: 24px ;
    font-size: 20px;
   
}
.footer-input{
    padding: 3px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: solid red;
    font-size: 18px;
    margin-bottom: 16px;
    border: solid;
}
.footer-links{

    width: 100%;
    max-width: 1000px;
    display: flex;
    justify-content: center;
    color: white;


}

.footer-link-wrapper{

    display: flex;
    
}
.footer-link-items{

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 16px;
    text-align: center;
    width: 160px;
    box-sizing: border-box;
    

}
.footer-link-itmes h2{
    margin-bottom: 16px;
    
}
.footer-link-itmes > h2{
    color: white;


}
.footer-link-items a{

    color: white;
    text-decoration: none;
    margin-bottom: 8px;

}
.footer-link-items a :hover{
    color: #e9e9e9;
    transition: 03s ease-out;
}
.footer-email-form h2{

    margin-bottom: 32px;

}
.footer-input::placeholder{

    color: black;

}
.social-icon-link{

    color: white;
    font-size: 24px;

}
.social-media {
    max-width: 1000px;
    width: 100%;

}
.social-media-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;

}
.social-icons{
    display: flex;
    justify-content: space-between;
    align-items: right;
    width: 100px;
  

}
.social-logo{

    color: white;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
    margin-bottom: 16px;
}
.website-rights{

    color: white;
    margin-bottom: 16px;

}
@media screen and (max-width:820px){

    .footer-links{

        padding-top: 2rem;
    }
    .footer-input{
        width: 100%;

    }
    .btn{
        width: 100%;
    }
    .footer-link-wrapper{
        flex-direction: row;
    }
    .social-media-wrap{
        flex-direction: row;
    }
}