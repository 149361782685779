.inner{

   
    overflow: auto;
    
}
.inner img{
overflow: auto;
    
    transition: all 3.5s  ease;
    

    
}





 


